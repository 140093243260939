/* 
 * @Author: 曹俊杰 
 * @Date: 2023-01-11 15:07:27
 * @Module: 企业认证
 */
 <template>
  <div class="CompanyAuth">
    <div class="top">
      <authStep :step="step" />
    </div>
    <div class="content">
      <div class="step1" v-show="step===1">
        <div class="title">
          <i class="el-icon-ali-xingzhuang1" style="color:#0062FF;margin-right:6px;"></i>
          <span>填写认证信息</span>
        </div>

        <el-form ref="form" label-position="left" :model="form" label-width="140px" :rules="rules" size="medium">
          <el-form-item label="公司名" prop="name">
            <el-input v-model="form.name" placeholder="请输入您的公司名"></el-input>
          </el-form-item>
          <el-form-item label="统一社会信用代码" prop="idNumber">
            <el-input v-model="form.idNumber" placeholder="请输入您的统一社会信用代码"></el-input>
          </el-form-item>
          <el-form-item label="法人代表姓名" prop="representativeName">
            <el-input v-model="form.representativeName" placeholder="请输入您的法人代表姓名"></el-input>
          </el-form-item>
          <el-form-item label="法人代表证件号" prop="representativeIdNumber">
            <el-input v-model="form.representativeIdNumber" placeholder="请输入您的法人代表证件号"></el-input>
          </el-form-item>
          <el-form-item label="法人代表手机号" prop="phone">
            <el-input v-model="form.phone" placeholder="请输入法人代表手机号"></el-input>
          </el-form-item>
        </el-form>

        <div class="btns">
          <div class="btns-l" @click="$router.go(-1)">返回</div>
          <div class="btns-r" @click="postAccountAuth">提交</div>
        </div>
      </div>

      <div class="step2" v-show="step===2">
        <div class="title">
          <i class="el-icon-ali-xingzhuang1" style="color:#0062FF;margin-right:6px;"></i>
          <span>扫码进行认证</span>
        </div>
        <div class="dec">
          <span>请使用{{form.name}}（{{form.idNumber}}）的</span>
          <span style="color:#0062FF;">「支付宝账号」</span>
          <span>账号扫码</span>
        </div>
        <vue-qr :text="authUrl" :size="300" style="margin-top:10px;"></vue-qr>
        <div class="btns" style="margin-top:40px">
          <div class="btns-l" @click="step=1">上一步</div>
          <!-- <div class="btns-r">提交</div> -->
        </div>
      </div>
      <div class="step3" v-show="step===3">
        <div class="title">
          <i class="el-icon-ali-xingzhuang1" style="color:#0062FF;margin-right:6px;"></i>
          <span>认证结果</span>
        </div>
        <el-result icon="success" title="认证成功">
        </el-result>
        <div class="btns" style="margin-top:40px">
          <div class="btns-l" @click="$router.push({name:'home'})">回到首页</div>
          <div class="btns-r" @click="$router.push({name:'CompanyAuth'})">企业认证</div>
        </div>
      </div>
    </div>
  </div>
</template>
 <script>
import authStep from '@/components/authStep'
let js;
import vueQr from 'vue-qr'
import { mapState } from "vuex";
export default {
  components: {
    authStep,
    vueQr
  },
  data () {
    const phoneValidator = (_, value, callback) => {
      if (!value) {
        return callback(new Error('手机号不能为空'));
      }
      if (this.$utils.test.mobile(value)) {
        return callback();
      } else {
        return callback(new Error('请输入正确的手机号'));
      }
    }
    const representativeIdNumber = (_, value, callback) => {
      if (!value) {
        return callback(new Error('法人代表证件号不能为空'));
      }
      if (this.$utils.test.idCard(value)) {
        return callback();
      } else {
        return callback(new Error('请输入正确的法人代表证件号'));
      }
    }
    return {
      step: 1,
      loading: false,
      form: {
        name: '',
        idNumber: '',
        representativeName: '',
        representativeIdNumber: '',
        phone: '',
      },
      authUrl: '',
      rules: {
        idNumber: [{ required: true, message: '统一社会信用代码不能为空', trigger: 'blur' }],
        representativeName: [{ required: true, message: '法人代表姓名不能为空', trigger: 'blur' }],
        representativeIdNumber: [{ required: true, validator: representativeIdNumber, trigger: 'blur' }],
        name: [{ required: true, message: '公司名不能为空', trigger: 'blur' }],
        phone: [{ required: true, validator: phoneValidator, trigger: 'blur' }],
      },
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    })
  },
  mounted () {
    this.form.phone = this.user.phone;
  },
  methods: {
    submitForm () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postAccountAuth()
        } else {
          return false;
        }
      });
    },
    showQR (data) {
      this.authUrl = data.authUrl
      // this.$refs.qrDialog.show(data.authUrl)
      this.step = 2;
      js = setInterval(() => {
        this.$api.getCompanyAuthTask({ taskUuid: data.taskUuid }).then(({ data: { success } }) => {
          if (success) {
            this.close()
            this.showSuccess()
            this.$store.dispatch('user/getAccount')
            // this.$store.dispatch('user/getCompanyMine')
          }

        })
      }, 1000);
    },
    showSuccess () {
      this.step = 3;
    },
    async postAccountAuth () {
      this.loading = true
      const { status, info, data } = await this.$api.postAccountCompanyAuth(this.form);
      if (status == 200) {
        this.$message({
          type: 'success',
          message: info
        })
        this.showQR(data)
      } else {
        this.$message({
          type: 'error',
          message: info
        })
      }
      this.loading = false
    },
    close () {
      js && clearInterval(js);
    }
  },
};
 </script>
 <style lang='scss' scoped>
.CompanyAuth {
  .top {
    height: 108px;
    background: #fff;
  }
  .content {
    padding-top: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-top: 16px;
    padding-bottom: 60px;
    .title {
      line-height: 60px;
      text-align: center;

      font-family: "Source Han Sans CN";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #262626;
    }
    .btns {
      display: flex;
      align-items: center;
      justify-content: center;
      &-l {
        width: 80px;
        line-height: 32px;

        background: #ffffff;
        border: 1px solid #e3e3e3;
        border-radius: 2px;
        margin-right: 14px;
        font-family: "PingFang SC";
        font-weight: 400;
        font-size: 14px;
        color: #262626;
        text-align: center;
        cursor: pointer;
      }
      &-r {
        width: 80px;
        line-height: 32px;
        background: #0d72ff;
        border-radius: 2px;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
        text-align: center;
        cursor: pointer;
      }
    }
    .step1 {
      width: 444px;
      margin: 0 auto;
    }
    .step2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      .dec {
        font-family: "Source Han Sans CN";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #666666;
        margin-top: 19px;
      }
    }
  }
}
</style>